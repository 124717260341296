

















































import { Component, Vue } from "vue-property-decorator";
import { readAdminUsers } from "@/store/admin/getters";
import { dispatchDeleteUser, dispatchGetUsers } from "@/store/admin/actions";

@Component
export default class AdminUsers extends Vue {
  private dialog: boolean = false;
  private userIdToDelete: number = -1;

  get userToDelete() {
    if (this.userIdToDelete == -1) {
      return null;
    }

    return this.users.filter((user) => user.id == this.userIdToDelete)[0];
  }

  public headers = [
    {
      text: "Name",
      sortable: true,
      value: "full_name",
      align: "left",
    },
    {
      text: "E-Mail",
      sortable: true,
      value: "email",
      align: "left",
    },
    {
      text: "Aktiv",
      sortable: true,
      value: "is_active",
      align: "left",
    },
    {
      text: "Administrator",
      sortable: true,
      value: "is_superuser",
      align: "left",
    },
    {
      text: "Aktionen",
      value: "id",
    },
  ];

  get users() {
    return readAdminUsers(this.$store);
  }

  public editUser(userId: number) {
    this.$router.push({
      name: "main-admin-users-edit",
      params: { id: userId.toString() },
    });
  }

  public async deleteUser(userId: number) {
    this.dialog = true;
    this.userIdToDelete = userId;
  }

  public async commitDeleteUser() {
    await dispatchDeleteUser(this.$store, this.userIdToDelete);
    await dispatchGetUsers(this.$store);
    this.dialog = false;
    this.userIdToDelete = -1;
  }

  public abortDeleteUser() {
    this.dialog = false;
    this.userIdToDelete = -1;
  }

  public async mounted() {
    await dispatchGetUsers(this.$store);
  }
}
